import BASE_URL from "./baseURL";

const getUploadUrl = () => {
  // const url = BASE_URL.replace("/api", "/daycare/api");
  const url = "https://daycareprodbe.kiddenz.com/daycare/api/";
  // const url = "https://kidadc.cartoonmango.com/daycare/api/";
  return url;
};
const UPLOAD_URL = getUploadUrl();

export const API_BLOGS = "blogs/";

export const API_LOGIN = "admin/admin-login/";
export const API_LOGOUT = "admin/admin-logout/";
export const API_MY_PROFILE = "/admin/list-users/?page=1&is_active=1";
export const API_INVITE = "provider-invite/";
export const API_PROVIDER_LIST = "providers/provider-list/";
export const API_PROVIDER_SEARCH_LIST = "providers/provider-search-list/";
export const API_PROVIDER_FILTER_LIST = "providers/get-filtered-providers/";
export const API_UPDATE_PROGRAM_STATUS_API =
  "program/update-status-online-program/";
export const API_GET_ONLINE_ORDERS = "online_order/get-online-orders/";
export const API_DASHBOARD_LATEST_PROGRAM = "program/latest-online-program/";
export const API_DASHBOARD_ENROLLED_PROGRAM =
  "program/enrolled-online-program/";

export const API_PROVIDER = "providers/";
export const API_PARENT = "parents/";
export const API_PROGRAM_LIST = "program/online-program-list/";
export const API_PROGRAM_PROVIDER_LIST = "program/get-online-list-provider/";
export const DELETE_ONLINE_PROVIDER_API = ({ id }) =>
  `${BASE_URL}program/delete-online-provider/${id}/`;
export const API_PROGRAM_STATUS_CHANGE_API = "program/change-status-program/";
export const API_PROGRAM_CATEGORY = "program/get-categories/";
export const API_ONLINE_PROGRAM = "program/get-online-programs/";
export const API_ADD_TO_DASHBOARD = "program/dashoard-list-api/";
export const API_ADD_TO_DASHBOARD_UPDATE = "program/dashoard-update-api/";
export const API_DASHBOARD_COUNT = "program/dashboard-count/";
export const API_REGISTERED_USERS = "parents/parent-list/";

export const ADD_BD = "bd/";

export const POST_ABOUT_PROGRAM_API = `${BASE_URL}program/about-program-api/`;
export const POST_REQUIREMENT_PROGRAM_API = `${BASE_URL}program/requirements-api/`;
export const POST_BATCH_PROGRAM_API = `${BASE_URL}program/batch-api/`;
export const POST_TRIAL_BATCH_PROGRAM_API = `${BASE_URL}program/trial-class/`;
export const POST_COST_PROGRAM_API = `${BASE_URL}program/cost-api/`;

export const POST_PROGRAM_CATEGORY_API = `${BASE_URL}program/category-api/`;
export const UPDATE_PROGRAM_CATEGORY_API = ({ id }) =>
  `${BASE_URL}program/update-category/${id}/`;
export const DELETE_PROGRAM_CATEGORY_API = ({ id }) =>
  `${BASE_URL}program/delete-category/${id}/`;
export const GET_PROGRAM_CATEGORY_API = `${BASE_URL}program/get-categories/`;
export const GET_PROGRAM_SUB_CATEGORY_API = `${BASE_URL}program/get-subcategory-for-category/`;

export const UPDATE_ABOUT_PROGRAM_API = ({ programId }) =>
  `${BASE_URL}program/update-online-program/${programId}/`;
export const UPDATE_REQUIREMENT_PROGRAM_API = ({ programId }) =>
  `${BASE_URL}program/update-online-requirement/${programId}/`;
export const UPDATE_COST_PROGRAM_API = ({ programId }) =>
  `${BASE_URL}program/update-cost/${programId}/`;
export const UPDATE_BATCH_PROGRAM_API = ({ programId }) =>
  `${BASE_URL}program/update-online-batch/${programId}/`;
export const UPDATE_TRIAL_BATCH_PROGRAM_API = ({ programId }) =>
  `${BASE_URL}program/update-trial-class/${programId}/`;

export const DELETE_BATCH_PROGRAM_API = ({ deleteId }) =>
  `${BASE_URL}program/delete-batch/${deleteId}/`;
export const DELETE_TRIAL_BATCH_PROGRAM_API = ({ deleteId }) =>
  `${BASE_URL}program/delete-trial-class/${deleteId}/`;

export const UPDATE_PROGRAM_STATUS_API = ({ programId }) =>
  `${BASE_URL}program/cost-api/${programId}`;

export const GET_PROGRAM_LISTING_API = `${BASE_URL}program/get-online-provider/`;
export const GET_CATEGORY_LISTING_API = `${BASE_URL}program/category-api/`;

export const GET_PROGRAM_BATCH_CHOICES_API = `${BASE_URL}program/get-batch-choices/`;
export const GET_PROGRAM_COST_CHOICES_API = `${BASE_URL}program/get-cost-choices/`;

export const GET_ONLINE_PROGRAM_PREVIEW_API = `${BASE_URL}program/preview-program/`;
export const GET_ONLINE_PROGRAM_STATUS_API = `${BASE_URL}program/get-filled-status/`;
export const GET_BATCHES_LIST_FOR_TRIAL_CLASS_API = `${BASE_URL}program/get-batches-api/`;
export const GET_ADDED_TRIAL_CLASSES_API = `${BASE_URL}program/trial-class/`;

export const GET_ABOUT_PROGRAM_API = `${BASE_URL}program/about-program-api/`;
export const GET_REQUIREMENTS_PROGRAM_API = `${BASE_URL}program/requirements-api/`;
export const GET_ALL_BATCHES_API = `${BASE_URL}program/batch-api/`;
export const GET_COST_PROGRAM_API = `${BASE_URL}program/cost-api/`;

export const IMAGE_UPLOAD_API = `${UPLOAD_URL}media/upload-file/`;
export const IMAGE_MULTIPLE_UPLOAD_API = `${UPLOAD_URL}media/upload-multiple-files/`;
export const VIDEO_UPLOAD_API = `${UPLOAD_URL}media/upload-misc-file/`;
