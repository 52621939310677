import React, { useReducer, useRef, useEffect } from "react";
import { Spinner } from "reactstrap";
import { HashRouter, Switch, Redirect, Router, Route } from "react-router-dom";
// import { Router, Route } from "react-router";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "./utils/axios";
import { API_LOGOUT, API_MY_PROFILE, API_PROVIDER_LIST } from "./config/apiEndpoints";
import toast from "./utils/toast";
import history from "./utils/history";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <Spinner color="primary" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/Login.js"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));

export const UserContext = React.createContext({ actions: {} });

const initialState = {
  profile: null,
  isLoggedIn: false,
  lastUpdated: 0,
  isLoading: true,
};
const profileReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_PROFILE":
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        profile: payload,
        lastUpdated: new Date().getTime(),
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: payload,
      };
    case "LOGOUT":
      return { ...initialState, isLoading: false };

    default:
      return state;
  }
};

// let nextPath = "/";

const App = (props) => {
  const [user, dispatch] = useReducer(profileReducer, initialState);
  const { isLoggedIn, isLoading } = user;
  const redirect = useRef(null);
  useEffect(() => {
    const token = window.localStorage.getItem("kiddenz-admin-production-token");
    if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    
     axios
    
      .get(API_PROVIDER_LIST)
      .then(res => res.data.data)
      .then(login)
      .catch(() => {
        redirect.current = "/login";
        // nextPath = props.location.pathname;
        dispatch({ type: "LOGOUT", payload: false });
      });
  }, []);

  const logout = () => {
    window.localStorage.removeItem("kiddenz-admin-production-token");
    redirect.current = "/login";
    dispatch({ type: "LOGOUT" });
    // axios
    //   .post(API_LOGOUT)
    //   .then(() => {
    //     redirect.current = "/login";
    //     dispatch({ type: "LOGOUT" });
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.message, {}, "LOGOUT");
    //   });
  };

  const login = (payload) => dispatch({ type: "SET_PROFILE", payload });
  return (
    <>
      <ToastContainer />
      {isLoading ? (
        loading()
      ) : (
      <UserContext.Provider value={{ actions: { login, logout }, user }}>
        <HashRouter>
        <React.Suspense fallback={loading()}>
          <Router history={history}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => {
                  if (isLoggedIn) {
                    return <Redirect to="/user-details" />;
                  }
                  return <Login {...props} />;
                }}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => {
                  if (!isLoggedIn) {
                    return <Redirect to="/login" />;
                  }
                  return <DefaultLayout {...props} />;
                }}
              />
              {redirect.current && (
                <Redirect
                  to={{
                    pathname: redirect.current,
                  }}
                />
              )}
            </Switch>
          </Router>
        </React.Suspense>
        </HashRouter>
      </UserContext.Provider>
     )}
    </>
  );
};

export default App;
