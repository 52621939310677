/**
 * This is axios intercepter which intercepts all the incoming and outgoing requests
 */
 import request from "axios";
 import baseURL from "../config/baseURL";
 
 const axios = request;
 // axios.defaults.withCredentials = true;
 // axios.defaults.headers.common.origin = 'www.example.com'; // for cookie based auth
 axios.interceptors.request.use(
   async config => {
     // if (!config.baseURL) {
     //   axios.defaults.baseURL = baseURL;
     //   config.baseURL = baseURL; // eslint-disable-line no-param-reassign
     // }
     // return config;
     if (!config.baseURL) {
       axios.defaults.baseURL = baseURL;
       config.baseURL = baseURL; // eslint-disable-line no-param-reassign
     }
     if (!config.headers.Authorization) {
       // setting token if it not present
       const token = window.localStorage.getItem("kiddenz-admin-production-token");
       if(token)
       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
     }
     return config;
   },
   // SPECIFIC_ERROR_HANDLER([], error);
   error => {
     console.log(error)
     Promise.reject(error);
   }
 );
 
 // eslint-disable-next-line arrow-body-style
 axios.interceptors.response.use(
   response => {
     if (response.config.url === baseURL + "admin/admin-login/") {
       console.log( response.data, " response.data")
 
       const token = response.data.data["token"] || response.data["token"];
       console.log('hi', token);
 
       // CookieManager.get(response.config.url).then(async res => {
       // `res` will be true or false depending on success.
       if(token)
       axios.defaults.headers.common.Authorization = `Bearer ${token}`;
       try {
   
           window.localStorage.setItem(
             "kiddenz-admin-production-token",
             token
           );
       } catch (error) {
         console.log('error while setting token in storage', error);
       }
       // });
     }
     return response;
   },
   error => {
     // Handle your common errors here
     // SPECIFIC_ERROR_HANDLER([500, 503], error);
     return Promise.reject(error);
   }
 );
 
 export default axios;
 