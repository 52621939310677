export const type = "dev"; // or dev / staging

const env = process.env.REACT_APP_SERVER_ENV || "development";
console.log(env);
const baseURL = {
  production: process.env.REACT_APP_PRODUCTION_API_URL,
  staging: process.env.REACT_APP_STAGING_API_URL,
  // development: "https://kzadminapi.cartoonmango.com/api/" // production
  // development: "https://adminprodbe.kiddenz.com/api/" // production
  // "http://13.235.13.205/api/"   // staging
  development:
    type === "staging"
      ? "https://adminbe.kiddenz.com/api/"
      : type === "development"
      ? "https://kidadc.cartoonmango.com/api/"
      : "https://adminprodbe.kiddenz.com/api/",
  // development: "http://13.235.31.26/api/"
  // development: "https://kidadc.cartoonmango.com/api/",
}[env];

export default baseURL;
